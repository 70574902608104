
























import { Component, Vue } from "vue-property-decorator";
import { Application } from "@/types";
import { namespace } from "vuex-class";
const ApplicationModule = namespace("ApplicationModule");
@Component({
  components: {
    CardEmployee: () => import("@/components/Card/CardEmployee.vue")
  }
})
export default class MenuEmployee extends Vue {
  @ApplicationModule.Getter
  application!: Application;
}
